import URI from '@core/utils/url';
import { getClientInstance } from '@core/graphql/client';
import paymentPendingVar from '@core/graphql/vars/paymentPendingVar';
import parsePathname from '@core/utils/url/parsePathname';
import {ViaEnum} from '@core/types/graphql';
import isPaySuccessUrl from '@core/utils/url/isPaySuccessUrl';
import clearUrl from '@core/utils/url/clearUrl';
import logger from '@core/logger';
import { localStorage } from '@core/utils/storage';
import STEPS from '@core/payment/paymentPending/constants/steps';
import trackPaymentPendingPageStep from '@core/tracking/trackPaymentPendingPageStep/trackPaymentPendingPageStep';
import { DISABLE_USER_PAYMENT_PROCESSED_INTERACTION } from '@core/payment/common/constants/localStorageKeys';
import USER_PAYMENT_PROCESSED_SUBSCRIPTION from '@core/payment/paymentPending/graphql/subscriptions/userPaymentProcessed.gql';
import PAYMENT_STATUSES from '@core/payment/paymentPending/constants/paymentStatuses';
import LAST_3DS_PAYMENT_STATUS_QUERY from '@core/payment/paymentPending/graphql/queries/last3dsPaymentStatus.gql';

const CHECK_3DS_STATUS_INTERVAL = 30000;

/**
 * @class PaymentPageExternalListenerModel
 * @see BaseModel
 */
var PaymentPageExternalListenerModel = BaseModel.extend({

    urlRoot: '/pay/spaCancelPayment',

    MICROFEATURES_VIA: ViaEnum.microFeatures,

    defaults: {
        /**
         * @type {string}
         */
        orderId: '',

        /**
         * @type {string}
         */
        declineUrl: '',

        /**
         * @type {string}
         */
        failVia: '',

        /**
         * @type {boolean} Allow to cancel. Not allowed for pending page flow.
         */
        isSpaCancelPaymentAllowed: true,
    },

    initialize: function () {
        this.onPostMessageHandler = this.onPostMessage.bind(this);

        /**
         * Start listen interaction of pending payment
         * Interaction need to inform front of receiving answer from payment processor
         */
        this.startListenPendingInteraction();
    },

    /**
     * Start listen post message in 3d's, or alt method flow
     * Post message sent's in php form that open in iframe
     * @public
     */
    startListenPostMessage: function () {
        window.addEventListener('message', this.onPostMessageHandler);
    },

    /**
     * @param data {object} data from interaction in pay pending process
     * @private
     */
    __onInteractionPaymentProcessed: function ({ data }) {
        if (!data?.paymentProcessed || localStorage.getItem(DISABLE_USER_PAYMENT_PROCESSED_INTERACTION)) {
            return;
        }

        const { paymentStatus, orderId, redirectUrl, via, declineScreenTemplate } = data.paymentProcessed;

        trackPaymentPendingPageStep({
            orderId,
            step: STEPS.DELIVERED,
        });

        this.__setRedirectUrl({ url: redirectUrl, via, orderId });

        this.__completeResponse({
            ...data.paymentProcessed,
            redirectUrl: this.get('redirectUrl'),
        });

        switch (paymentStatus) {
            case PAYMENT_STATUSES.SUCCESS:
                this.__successResponse(orderId);

                break;
            case PAYMENT_STATUSES.FAIL:
                this.__failResponse(via, declineScreenTemplate);

                break;
            default:
                logger.sendError(
                  `[PaymentPageExternalListenerModel] Wrong status in processing interaction message : ${paymentStatus}`
                );
        }

        this.stopListenPendingInteraction();
    },

    /**
     * Trigger fail after end of pending timeout
     * @public
     */
    setTimeoutResponse: function() {
        this.__failResponse(this.get('failVia'));
    },

    /**
     * define that it's 3DS message and trigger result
     * @param message
     * @public
     */
    onPostMessage: function (message) {
        this.processPaymentAnswer(message);
    },

    /**
     * @public
     * @param {object} message
     */
    processPaymentAnswer(message) {
        if (!message.data || !message.data.resultPageMessage) {
            return;
        }
        const { failPageLogic, orderId, prevVia, redirectUrl, status, via, declineScreenTemplate } = message.data;

        this.__setRedirectUrl({ url: redirectUrl, via, prevVia, orderId });
        this.set('failVia', failPageLogic);

        switch (status) {
            case PAYMENT_STATUSES.SUCCESS:
                this.__completeResponse(message.data);
                this.__successResponse(orderId);

                break;
            case PAYMENT_STATUSES.PENDING:
                this.__pendingResponse();

                break;
            case PAYMENT_STATUSES.FAIL:
                this.__completeResponse(message.data);
                this.__failResponse(failPageLogic, declineScreenTemplate);

                break;
            default:
                logger.sendError(
                  `[PaymentPageExternalListenerModel] Wrong status in processing post message : ${status}`
                );
        }

        this.stopListenPostMessage();
    },

    __completeResponse: function(data) {
        this.trigger('complete', data);
    },

    /**
     * Trigger fail payment try
     * @private
     */
    __pendingResponse: function() {
        paymentPendingVar(true);
        this.set('isSpaCancelPaymentAllowed', false);
        this.trigger('pending');
    },


    /**
     * Trigger fail payment try
     * @param failPageLogic
     * @param {null|String} declineScreenTemplate
     * @private
     */
    __failResponse: function(failPageLogic, declineScreenTemplate = null) {
        this.trigger('fail', {
            redirectUrl: this.get('redirectUrl'),
            failPageLogic: failPageLogic,
            status: false,
            declineScreenTemplate,
        });
    },

    /**
     * Trigger success payment
     * @param orderId
     * @private
     */
    __successResponse: function(orderId) {
        /**
         * @see PaymentPageExternalPendingView:__onModelResults
         */
        this.trigger('closePendingPopup');
        this.trigger('success', {
            redirectUrl: this.get('redirectUrl'),
            orderId: orderId,
            status: true,
        });
    },

    /**
     * @public
     */
    stopListenPendingInteraction: function () {
        this.pendingSubscription?.unsubscribe();
    },

    /**
     * Restores the listeners of the model, since they stop after the socket event arrives
     * and the model is cached in the base model until the page is reloaded
     * this scheme is needed for repeated pay-declines
     * @see this::stopListenPendingInteraction
     *      PaymentPagePaymentBaseModel::getExternalPayModelWithListeners
     * @public
     */
    startListenPendingInteraction() {
        this.pendingSubscription = getClientInstance()
            .subscribe({ query: USER_PAYMENT_PROCESSED_SUBSCRIPTION })
            .subscribe(this.__onInteractionPaymentProcessed.bind(this));
    },

    /**
     * @public
     */
    stopListenPostMessage: function () {
        window.removeEventListener('message', this.onPostMessageHandler);
    },

    /**
     * Need for right redirect url on microfeatures decline page
     * @todo make in on server
     * @param {Object} oprtions
     * @param {string} oprtions.url
     * @param {string} oprtions.via
     * @param {string} oprtions.prevVia
     * @param {string} oprtions.orderId
     * @private
     */
    __setRedirectUrl({ url, via, prevVia, orderId }) {
        if (url === '/pay/' + this.MICROFEATURES_VIA) {
            return;
        }

        /** temp fix for right microfeatures url */
        if (via === this.MICROFEATURES_VIA && !isPaySuccessUrl(clearUrl(url))) {
            url = URI('/pay/' + this.MICROFEATURES_VIA);
            url.removeSearch('via');
            url.addSearch('via', prevVia);
        } else {
            url = URI(url);
            url.removeSearch('via');
            url.addSearch('via', via);
        }

        if (prevVia) {
            url.removeSearch('prevVia');
            url.addSearch('prevVia', prevVia);
        }

        if (!url.hasSearch('orderId') && orderId) {
            url.addSearch('orderId', orderId);
        }

        this.set('redirectUrl', url.resource());
    },

    /**
     * Send response to server for break payment session
     * @public
     */
    spaCancelPayment: function () {
        if (this.get('isSpaCancelPaymentAllowed')) {
            this.fetch();
            this.trigger('cancel');
        }
    },

    start3dsStatusChecker() {
        this.statusCheckTimer = setInterval(() => this.check3dsStatus(), CHECK_3DS_STATUS_INTERVAL);
    },

    stop3dsStatusChecker() {
        clearInterval(this.statusCheckTimer);
    },

    /**
     * 3ds ajax status checker in case when intreaction message is lost
     */
    async check3dsStatus() {
        const {
            data: {
                payment: {
                    last3dsPaymentStatus,
                    redirectUrl: {
                        afterPaymentSuccess,
                        afterPaymentFail,
                    }
                },
            },
        } = await getClientInstance().query({
            query: LAST_3DS_PAYMENT_STATUS_QUERY,
            variables: { action: this.get('action'), orderId: this.get('orderId') },
            fetchPolicy: 'network-only'
        });

        if (![ PAYMENT_STATUSES.SUCCESS, PAYMENT_STATUSES.FAIL ].includes(last3dsPaymentStatus)) {
            return;
        }

        const isSuccessPayment = last3dsPaymentStatus === PAYMENT_STATUSES.SUCCESS;

        this.set('redirectUrl', isSuccessPayment ? afterPaymentSuccess : afterPaymentFail);
        this.__completeResponse({});

        if (isSuccessPayment) {
            if (!this.get('orderId')) {
                logger.sendWarning('[PaymentPageExternalListenerModel] No orderId');
            }
            this.__successResponse(this.get('orderId'));
        } else {
            const failPathname = parsePathname(afterPaymentFail);
            const failPageLogic = failPathname.options?.via;
            this.__failResponse(failPageLogic || ViaEnum.inpage_decline);
        }
    },
});
export default PaymentPageExternalListenerModel;
