/**
 * Length of first part of card by design
 * @type {number}
 */
const BIN_LENGTH = 4;

/**
 * Get formatted card wallet
 * @deprecated Use getCardWallet.ts
 * @class CardWalletModel
 */
const CardWalletModel = BaseModel.extend({
    urlRoot: '/pay/cardWallet',

    defaults: {
        bin: null,
        last4Digits: null,
        isPanOnly: false,
    },

    data() {
        return {
            walletToken: this.get('walletToken'),
            cardWalletType: this.get('cardWalletType'),
        };
    },

    /**
     * @public
     * @param {String} walletToken
     * @param {String} cardWalletType
     * @return {Promise<unknown>}
     */
    getCard(walletToken, cardWalletType) {
        return new Promise((resolve, reject) => {
            this.save({
                walletToken,
                cardWalletType,
            }, {
                success: (_, data) => {
                    if (data.status === false) {
                        reject(data.message);

                        return;
                    }

                    if (!data.bin || !data.last4Digits || !data.isPanOnly) {
                        resolve(null);

                        return;
                    }

                    resolve(`${String(data.bin).slice(0, BIN_LENGTH)} **** **** ${data.last4Digits}`);
                },
            });
        });
    },
});

export default CardWalletModel;
